import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = ({ data, location }) => {
  	const siteTitle = data.site.siteMetadata?.title || `Title`

	return (
		<Layout location={location} title={siteTitle}>
			<SEO title="Om oss" />

			<div className="global-wrapper">
				<h2 style={{marginTop: 20}}>Om oss</h2>
				<p>Vad är egentligen viktigast om vi vill leva ett långt och hälsosamt liv? <br />
				Vad ska vi äta och vad ska vi inte äta? <br />
				Ska vi träna och i så fall vad? <br />
				Hur ska vi sova och hur mycket?</p>
				<p>Dessa frågor började Kristoffer och Liza Fürst grubbla över 2019. Väldigt lite fanns att tillgå enkelt, så det slutade med att de grottade ner sig i forskarstudier och började läsa böcker utgivna av forskare på området. Ämnet visade sig vara krångligt, men det visade sig ändå finnas tydliga belägg för vad som är troligt att det kan förlänga människors liv. Det visade sig även finnas det omvända och slutligen saker som kanske förvisso kan anses "nyttiga" men i praktiken har väldigt liten effekt.</p>
				<p>Liza och Kristoffer ville dela med sig av denna kunskap. Dom frågade Kristoffers bror Markus om han ville vara med och skapa en blog och podcast, och han var snabb på att nappa! Det blev startskottet för EverHealth!</p>
				<p>* <b>Kristoffer Fürst</b> är matematiker från KTH och arbetar med produktutveckling inom finansbranschen.<br />
				* <b>Liza Fürst</b> är civilekonom från Handelshögskolan och arbetar som dataanalytiker.<br />
				* <b>Markus Fürst</b> är kandidat i träningslära från Wingate University och studerar nu fysiologi på University of Florida.</p>
				<p>Ansvar<br /><i>Informationen som finns på EverHealths hemsida, blogg, podcast eller annat medium är endast avsett för utbildningsändamål. Det är inte avsett eller antytt att ersätta professionell medicinsk rådgivning. Läsaren bör alltid rådfråga sin vårdgivare för att avgöra om informationen är lämplig för hans eller hennes situation, eller om han eller hon har några frågor om ett medicinskt tillstånd eller en behandlingsplan.  EverHealth är inte en vårdgivare. Att läsa eller lyssna på EverHealths hemsida, blogg, podcast eller annat medium, utgör inte ett förhållande mellan vårdgivare och patient. Produkter eller kosttillskott som omnämns är inte avsedda att diagnostisera, behandla, förebygga eller bota någon sjukdom. Författarna frånsäger sig allt ansvar för eventuella negativa effekter som kan uppstå genom användning eller tillämpning av information som finns på EverHealths hemsida, blogg, podcast eller annat medium.</i></p>
			</div>
		</Layout>
	)
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
